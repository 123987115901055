import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import cx from 'classnames';
import { isNumber } from 'lodash-es';
import { useToggle } from '@beewise/react-utils';
import Tooltip from '@beewise/tooltip';
import { getCurrentBhome } from 'components/views/BeeHome/selectors';
import analytics, { analyticsConstants } from 'utils/analytics';
import useGetFrameStationHiveAndFrameNumbers from 'utils/useGetFrameStationHiveAndFrameNumbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraCctv, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { toggleRightPanel } from 'actions';
import { getIsLimitedViewCompany } from 'selectors';
import KinesisStream from './KinesisStream';

import './RightPanelHeader.scss';

const RightPanelHeader = ({ frame, onTitleClick, isMapShown }) => {
    const dispatch = useDispatch();
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const isLimitedView = useSelector(getIsLimitedViewCompany);

    const [isOpen, toggleModal] = useToggle();

    const isFrameShown = useMemo(() => isNumber(frame.id), [frame.id]);

    const frameInfo = useGetFrameStationHiveAndFrameNumbers(frame);

    const handleLiveStreamOpen = useCallback(() => {
        if (!currentBhome?.live_stream) {
            return;
        }

        analytics.fireLiveStreamEvent({
            action: analyticsConstants.EVENT_NAMES.liveStream,
            label: 'Activity - opened',
        });

        const srcRegexpPattern = /(src=")(.*)(")/;
        const liveStreamUrl = currentBhome.live_stream.match(srcRegexpPattern)[2];
        liveStreamUrl && window.open(liveStreamUrl, '__blank');
    }, [currentBhome.live_stream]);

    const handlePanelClose = useCallback(() => {
        dispatch(toggleRightPanel(false));
    }, [dispatch]);

    const frameInfoText = frameInfo?.text;

    return (
        <div className="right-panel-header">
            <div className="right-panel-header-title">
                <div
                    className={cx('right-panel-header-bhome-info', {
                        'is-active': !isFrameShown,
                    })}
                    role="presentation"
                    onClick={onTitleClick}
                >
                    <div className="right-panel-header-location">{currentBhome.location}</div>
                    <div className="right-panel-header-id">(#{currentBhome.id})</div>
                </div>
                {isFrameShown && (
                    <>
                        <div className="right-panel-header-separator">/</div>
                        <div
                            role="presentation"
                            className="right-panel-header-frame-info is-active"
                            onClick={() => navigator.clipboard.writeText(frame.rfid)}
                        >
                            {frameInfoText}
                        </div>
                    </>
                )}
            </div>
            <div className="right-panel-header-buttons-wrapper">
                {!isLimitedView && (
                    <Tooltip
                        text="Open live stream"
                        position="left center"
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                        hideOnScroll
                    >
                        <FontAwesomeIcon
                            icon={faCameraCctv}
                            className="right-panel-header-live-stream"
                            onClick={handleLiveStreamOpen}
                        />
                    </Tooltip>
                )}
                {isMapShown && (
                    <Tooltip
                        offset={[12, 0, 0, 0]}
                        text="Close"
                        position="top left"
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                        hideOnScroll
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="right-panel-header-close"
                            onClick={handlePanelClose}
                        />
                    </Tooltip>
                )}
            </div>
            <KinesisStream bhome={currentBhome} toggleModal={toggleModal} isOpen={isOpen} />
        </div>
    );
};

RightPanelHeader.propTypes = {
    frame: PropTypes.shape().isRequired,
    onTitleClick: PropTypes.func.isRequired,
    isMapShown: PropTypes.bool,
};

export default RightPanelHeader;
