import React, { useCallback, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import useGranot from 'utils/isGranot';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Tooltip from '@beewise/tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import constants from 'appConstants';
import { setBhomeArrived } from 'components/views/Dashboard/actions';
import { getBhomeUnreadAlerts } from 'components/reusables/Alerts/selectors';
import { getCurrentBhome } from 'components/views/BeeHome/selectors';
import AlertIcon from 'components/reusables/Alerts/AlertIcon';
import { toggleMap, toggleRightPanel } from 'actions';
import { setCurrentBhome, setZoomLevel } from 'components/views/BeeHome/actions';
import MarkerWrapper from '../MarkerWrapper';
import MarkerIcon from '../MarkerIcon';
import './BhomeMarker.scss';

const BhomeMarker = ({ location, map }) => {
    const markerRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locationPath = useLocation();
    const isGranot = useGranot();
    const zoomLevel = map ? map.getZoom() : 0;

    const isOldBhomePage = locationPath.pathname.includes('beehome');

    const bhomeUnreadAlerts = useSelector(
        state => getBhomeUnreadAlerts(state, location.bhome_ids),
        arrayOfObjectsShallowEqual
    );
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);

    const handleMarkerClick = useCallback(() => {
        if (location.bhome_ids.length !== 1) {
            return;
        }
        dispatch(setZoomLevel({ zoomLevel }));
        if (currentBhome.id !== location.bhome_ids[0]) {
            const path = isOldBhomePage ? 'beehome' : 'bhome';
            navigate(`/${path}/${location.bhome_ids[0]}`);
        }
        // TODO remove with new map
        if (isOldBhomePage) {
            dispatch(setCurrentBhome({}));
            dispatch(toggleRightPanel(true));
            dispatch(toggleMap());
        }
    }, [location.bhome_ids, dispatch, zoomLevel, currentBhome.id, isOldBhomePage, navigate]);

    const handleAnimationEnd = useCallback(() => {
        if (location.movingToLocation && location.id) {
            dispatch(setBhomeArrived(location.id));
        }
    }, [location.id, location.movingToLocation, dispatch]);

    const isSelectedBhome = location?.bhome_ids?.includes?.(currentBhome?.id);

    const MarkerWrapComponent = !location.id ? Tooltip : MarkerWrapper;
    const wrapComponentProps = !location.id
        ? { content: `BH ${location.bhome_ids[0]}`, position: 'top center', className: 'bhome-tooltip' }
        : { map, id: location.id, name: location.name, bhomeIds: location.bhome_ids, ref: markerRef };

    return (
        <MarkerWrapComponent {...wrapComponentProps}>
            <MarkerIcon
                onClick={handleMarkerClick}
                onAnimationEnd={handleAnimationEnd}
                className={cx({
                    'marker-animated': location.movingToLocation,
                    'is-selected': isSelectedBhome,
                    'marker-large': !!location?.bhome_ids?.length && zoomLevel < 8,
                    'bhome-unassigned': !isSelectedBhome && !location.ranch_id && !location.yard_id,
                    'bhome-assigned': (!isSelectedBhome && location.ranch_id) || location.yard_id,
                })}
                icon="blured-marker"
                count={location?.bhome_ids?.length > 1 ? location?.bhome_ids?.length : null}
                ref={markerRef}
            >
                {!isGranot && !!bhomeUnreadAlerts.length && (
                    <AlertIcon
                        className="marker-alert"
                        alerts={bhomeUnreadAlerts}
                        type={constants.ALERT_COMPONENT_LEVEL.BHOME}
                    />
                )}
            </MarkerIcon>
        </MarkerWrapComponent>
    );
};

BhomeMarker.propTypes = {
    location: PropTypes.shape(),
    map: PropTypes.shape(),
};

export default BhomeMarker;
